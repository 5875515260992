








































































































.link-image {
  max-width: 75px;
  object-fit: contain;
}

.link-text {
  width: 7rem;
}
